@import 'src/scss/variables';
@import 'src/scss/mixins';

.Trash {
  position: absolute;
  right: 1rem;
  top: 42%;
  width: 3rem;
  height: 3rem;
  fill: $color-danger-500;
}

.Icon {
  position: absolute;
  right: 1rem;
  top: 62%;
  width: 3rem;
  height: 3rem;
  fill: $color-grey-500;
}
