@import 'src/scss/mixins';
@import 'src/scss/variables';

.orderList {
  display: flex;
  flex-direction: column;
  max-height: 92vh;
  overflow-y: auto;
  margin-right: 2rem;
  margin-top: 2rem;
  padding-left: 2rem;

  @include respond(tab-port) {
    margin-top: 1rem;
    margin-right: 0;
    max-height: 97vh;
    padding-left: 0;
  }
}

.order {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 1rem;

  background-color: $color-secondary-100;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @include respond(phone) {
    min-height: 12rem;
    overflow-y: hidden;
    padding: 0.5rem;
  }

  @media (prefers-color-scheme: dark) {
    background-color: $color-grey-800;
  }

}

.rightArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 15%;

  @include respond(phone) {
    flex: 0 0 25%;
    text-align: center;
  }
}

.leftArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 10%;

  @include respond(phone) {
    flex: 0 0 15%;
  }
}

.header {
  color: $color-secondary-400;
  @include respond(phone) {
    line-height: 1.2;
  }
}

.underHeader {
  transform: translateY(-1rem);

  @include respond(phone) {
    transform: translateY(0);
    line-height: 1.2;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }
}

.productBox {
  flex: 0 0 70%;

  @include respond(tab-port) {
    flex: 0 0 50%;
    width: 50%;
  }
}

.product {
  display: flex;
  justify-content: space-between;
}

.product > *:not(:first-child) {
  margin-left: 1rem;
}

.name {
  flex: 0 0 50%;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include respond(phone) {
    flex: 0 0 75%;
  }
}

.size {
  flex: 0 0 15%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
}

.price {
  flex: 0 0 15%;
}

.color {
  flex: 0 0 20%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
}
