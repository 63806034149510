@import 'src/scss/mixins';

.form {
  padding: 3rem;
  width: 50vw;

  @include respond(phone) {
    width: 100%;
  }
}
