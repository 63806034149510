@import 'src/scss/variables';
@import 'src/scss/mixins';

.plContainer {
  order: 3;
}

.infoBox {
  display: flex;
  //flex-direction: row;
  //padding: 1rem;

  &:not(:first-child) {
    //margin-top: 0.1rem;
    //border-top: 2px solid $color-grey-400;
  }
}

.value {
  display: block;
  background-color: $color-secondary-500;
  flex-basis: 50%;
  text-align: right;
  padding: 1rem;
  color: $color-primary-900;
  font-weight: 700;
}

.label {
  background-color: $color-primary-500;
  //display: flex;
  //flex-basis: 50%;
  padding: 1rem;
}