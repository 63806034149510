@import 'src/scss/mixins';
@import 'src/scss/variables';

.Inventory {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
  }
}

.btn {
  grid-column: 3 / -1;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2rem 0 0;
}
