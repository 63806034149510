@import 'src/scss/mixins';
@import 'src/scss/variables';

.row {
  display: flex;
  border-bottom: 2.5px solid #f1f1f1;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @include respond(tab-port) {
    flex-direction: column;
    align-items: start;
    padding: 1rem;
  }
}

.row:hover {
  background-color: lightgrey;
}

.iconBox {
  width: 8rem;

  @include respond(tab-port) {
    position: absolute;
    right: 0;
    top: 2rem;
  }
}

.Icon {
  height: 3rem;
  width: 3rem;
}

.name {
  width: 30rem;
  @include respond(tab-port) {
    border-bottom: 2px solid var(--color-secondary);
    width: max-content;
  }
}

.email {
  width: 30rem;

  @include respond(tab-port) {
    width: auto;
  }
}

.extra {
  width: 10rem;
  margin-left: 1rem;
  font-size: 1.6rem;
  color: rgba(0, 0, 0, 0.5);

  @include respond(tab-port) {
    margin-left: 0;
  }
}

.trash {
  fill: darkred;
}
