@import 'src/scss/variables';
@import 'src/scss/mixins';

.form {
  //display: flex;
}

.form > div > input {
  width: 50%;
}

.form > div > textarea {
  width: 70%;
}

.btnGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  & > :first-child {
    margin-right: 1rem;
  }
}
