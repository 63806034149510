@import 'src/scss/mixins';
@import 'src/scss/variables';

.shop {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));

  grid-gap: 2rem;
  margin-top: 8rem;
  margin-right: 2rem;
  margin-left: 2rem;

  @include respond(tab-port) {
    margin-top: 15rem;
    grid-template-columns: 1fr 1fr;
  }
}

.product {
  //border: 2px solid $color-grey-700;
  padding: 1rem;
  border-radius: 1rem;
  //box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.cart {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  right: 0;
  width: 10rem;
  height: 5rem;
  border-radius: 3rem;
  background-color: #20bf6b;

  font-size: 2rem;

  transform: translateY(-7rem);
}

.user {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  //width: rem;
  padding: 1rem;
  height: 5rem;
  border-radius: 3rem;
  background-color: #20bf6b;

  font-size: 2rem;

  transform: translateY(-7rem);

  @include respond(tab-port) {
    //flex-direction: row;
  }
}

.stripe {
  position: absolute;
  top: 0;
  left: 50%;
  //width: rem;
  padding: 1rem 3rem;
  height: 5rem;
  border-radius: 3rem;
  background-color: #20bf6b;
  color: $color-grey-100;

  font-weight: 400;
  font-size: 2rem;

  transition: all 0.2s;
  transform: translateY(-7rem);
}

.blue {
  background-color: #635bff;
}

.noCart {
  background-color: $color-grey-600;
}

.cartName {
  color: $color-grey-100;
  margin-left: 1rem;
  font-weight: 400;
}

.icon {
  width: 4rem;
  height: 4rem;
  fill: $color-grey-100;
}

.num {
  margin-left: 1rem;
  color: $color-grey-100;
  font-weight: 400;
}

.image {
  display: block;
  width: 30rem;

  @include respond(tab-port) {
    width: 20rem;
  }
}
