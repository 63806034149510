.Attendance {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60rem;
  position: relative;
}

.Attendance > *:last-of-type {
  align-self: flex-end;
}

@media only screen and (max-width: 37.5em) {
  .Attendance {
    width: auto;
  }
}
