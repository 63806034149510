@import 'src/scss/variables';
@import 'src/scss/mixins';

.formContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 3px #ccc;
  padding: 5rem;
  width: 30vw;

  @media (prefers-color-scheme: dark) {
    box-shadow: 0 2px 3px black;

  }
}

.title {
  font-size: 3rem;
  font-weight: 300;
  text-align: center;
}

@media only screen and (max-width: 56.25em) {
  .formContainer {
    width: 70vw;
  }
}

@media only screen and (max-width: 37.5em) {
  .formContainer {
    width: 100%;
    box-shadow: none;
  }
}
