@import 'src/scss/variables';

.nav {
  display: flex;
  height: 6rem;
  background-color: white;

  align-items: flex-end;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (prefers-color-scheme: dark) {
    background-color: $color-grey-900;
    box-shadow: 0 2px 10px rgba($color-grey-100, 0.1);
  }
}

.item {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 300;
  border-bottom: 2px solid transparent;

  transition: all 0.2s;

  &:first-child {
    margin-left: 1rem;
  }

  &:hover {
    color: #419ea1;
    border-bottom: 2px solid currentColor;
  }
}
