@import 'src/scss/mixins';
@import 'src/scss/variables';

.infoBox {
  padding: 2rem;
  border: 2px solid $color-secondary-900;
  border-radius: 2rem;
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;

  //justify-content: center;
  align-items: center;

  background-color: $color-secondary-200;

  &:first-child {
    //margin-top: 2rem;
    //margin-top: 12rem;
  }
}

.header {
  font-size: 2rem;
  color: $color-secondary-700;
  text-align: center;
  //text-transform: uppercase;
  height: 40%;
}

.content {
  font-size: 5rem;
  //margin-top: 1.4rem;
  color: $color-secondary-900;
  //margin-top: auto;
  //margin-bottom: auto;
}

.span {
  grid-column: span 2;
}
