@import 'src/scss/variables';
@import 'src/scss/mixins';

.box {
  height: 20rem;
  width: 80%;
  border-radius: 2rem;
}

.title {
  text-align: center;
  font-size: 3rem;
  color: $color-grey-700;
}

.list {
  list-style: none;
  padding: 2rem;
}

.text {
  margin-left: 0.5rem;
}

.listItem {
  transition: all 2s;
  display: flex;

  &:hover svg {
    transition: all 0.6s;
    //border: 2px solid orangered;
    fill: $color-success-500;
  }

  & + svg {
    transition: all 2s;

    &:hover {
      border: 2px solid red;
    }
  }
}

.iconActive {
  width: 2rem;
  height: 2rem;
  border: 2px solid $color-secondary-400;
  fill: $color-success-500;
  transition: all 2s;
}

.iconInactive {
  width: 2rem;
  height: 2rem;
  border: 2px solid $color-secondary-400;
  fill: none;

  transition: all 2s;

  &:hover {
    fill: $color-success-500;
  }
}
