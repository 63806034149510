@import 'src/scss/mixins';

.main {
  grid-column: 3 / full-end;
  grid-row: 2 / 3;
  //position: relative;

  @include respond(tab-port) {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    grid-template-rows: 8rem minmax(85vh, min-content);
  }
}
