@import 'src/scss/variables';
@import 'src/scss/mixins';

.filter {
  position: absolute;
  top: 2rem;
  right: 2rem;
  background-color: $color-primary-500;
  padding: 1rem 2rem;
  border-radius: 2rem;
  z-index: 200;
  //transform: translateX(-25vw);

  transition: all 0.5s;
  //transform: translateX(10rem);

  &:hover {
    //transform: translateY(-2px);
  }

  &:active {
    //transform: translateY(0);
  }

  @include respond(tab-port) {
  }
}

.filterActive {
  transform: translateX(-25vw);

  @include respond(tab-port) {
    transform: translateX(-70vw);
  }
}

.filterBox {
  position: absolute;
  top: 0;
  right: -10px;
  width: 30%;
  height: 80%;
  padding: 2rem;
  z-index: 200;
  background-color: $color-secondary-500;

  display: flex;
  flex-direction: column;
  align-items: center;

  transition: all 0.5s;

  transform: translateX(100%);

  @include respond(tab-port) {
    padding: 10rem 2rem;
    width: 70%;
    height: 100vh;
    //transform: translateY(-6.3%);
  }
}

.filterBoxActive {
  transform: translateX(0);
  visibility: visible;
  display: flex;

  @include respond(tab-port) {
    transform: translate(0, 0);
  }
}

.months {
  order: 2;
  @include respond(tab-port) {
    font-size: 4rem;
  }
}

.yearBox {
  order: 1;
  @include respond(tab-port) {
    font-size: 4rem;
  }
}

.arrows {
  display: flex;
  align-items: center;
}

.icon {
  width: 3rem;
  height: 3rem;

  @include respond(tab-port) {
    width: 4rem;
    height: 4rem;
  }
}

.paymentTypeBox {
  //display: flex;
  //width: 100%;
  //justify-content: center;
  order: 2;
  & > * {
    margin: 2rem;
  }
}

.lilBox {
  padding: 1rem 2rem;
  border: 2px solid $color-grey-400;
  border-radius: 2rem;

  text-align: center;

  &:not(last-child) {
    margin-bottom: 2rem;
  }
}