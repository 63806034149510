@import 'src/scss/variables';
@import 'src/scss/mixins';

.Modal {
  position: fixed;
  z-index: 500;
  background-color: $color-grey-100;
  border: 1px solid $color-grey-300;
  box-shadow: 0 1px 5px #999494;
  padding: 1.6rem;
  top: 50%;
  left: 50%;
  transform: translateX(20%);
  transition: all 0.3s ease-out;
  overflow-y: auto;

  @media (prefers-color-scheme: dark) {
    background-color: $color-grey-900;
    border: 1px solid $color-grey-900;
    box-shadow: 0 1px 5px #000;
  }
}

@media only screen and (max-width: 37.5em) {
  .Modal {
    width: 98vw;
  }
}

.noPadding {
  padding: 0 !important;
  border: none;
}
