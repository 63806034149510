@import 'src/scss/mixins';
@import 'src/scss/variables';

.order {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  padding: 4rem;
  width: 75vw;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @include respond(tab-port) {
    grid-template-columns: 1fr 1fr;
    width: auto;
    padding: 1rem;
    grid-row-gap: 2rem;
  }

  @include respond(phone) {
    min-height: 12rem;
    overflow: hidden;
  }
}

.rightArea {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond(phone) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    align-items: start;
    flex-direction: row;
    justify-content: space-around;
  }
}

.leftArea {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond(phone) {
    align-items: start;
    grid-column: 1 / 2;
    flex-direction: row;
    justify-content: space-around;
  }
}

.header {
  color: $color-secondary-400;
  text-align: center;

  @include respond(phone) {
    line-height: 1.2;
    text-align: center;
  }
}

.noteBox {
  grid-column: 1 / 3;
}

.note {
  color: $color-secondary-400;
}

.underHeader {
  transform: translateY(-1rem);
  text-align: center;

  @include respond(phone) {
    transform: translateY(0);
    line-height: 1.2;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    text-align: center;
  }
}

.productBox {
  @include respond(tab-port) {
    flex: 0 0 50%;
    grid-column: 1 / -1;
  }
}

.product {
  display: flex;
  justify-content: space-between;

  @include respond(tab-port) {
    flex-direction: column;
  }
}

.innerBox {
  display: flex;
  flex: 0 0 40%;
  justify-content: space-around;
}

.product > *:not(:first-child) {
  margin-left: 1rem;
}

.name {
  flex: 0 0 60%;
  width: auto;

  @include respond(phone) {
  }
}

.size {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
}

.color {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
}
