@import 'src/scss/variables';
@import 'src/scss/mixins';

.membership {
  //border: 2px solid $color-primary-100;
  //border-radius: 4rem;
  //padding: 2rem;

  position: relative;

  &:not(:last-child) {
    //margin-bottom: 4rem;
  }
}

.accountHolder {
  text-align: center;
  padding: 1rem 0 2rem 0;
  border-top-right-radius: 4rem;
  border-top-left-radius: 4rem;
  background-color: $color-primary-300;
  position: relative;

  & p {
    font-family: $font-display;
    font-size: 2rem;
    color: $color-secondary-800;
  }

  & span {
    //position: absolute;
    //top: 0;
    //left: 0;
    @include absCenter();
    top: 80%;
  }
}

.info {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-around;
  background-color: $color-grey-200;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
  //padding-bottom: 1rem;
}

.priceCash {
  font-family: $font-display;
  font-weight: 700;
  color: $color-success-500;
}

.priceStripe {
  font-family: $font-display;
  font-weight: 700;
  color: $color-stripe;
}

.status {
  color: $color-secondary-500;
  font-weight: 700;
}

.date {
  //color: $color-grey-500;
}

.oldDate {
  //fill: $color-danger-500;
  color: $color-danger-500;
}
