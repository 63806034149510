@import 'src/scss/variables';

.NavigationLink {
  text-decoration: none;
  color: white;
  font-size: 2rem;
  width: 100%;

  padding: 1rem 1rem 1rem 4rem;

  transition: all 0.2s;

  border-left: 5px solid transparent;

  &:hover {
    border-left: 5px solid $color-primary-400;
    color: $color-primary-500;
    background-color: $color-secondary-800;
  }

  &.Active {
    border-left: 5px solid $color-primary-400;
    color: $color-primary-500;
    background-color: $color-secondary-800;
  }
}
