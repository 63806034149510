@import 'src/scss/variables';
@import 'src/scss/mixins';

.userInfo {
  background-color: $color-grey-100;

  display: flex;
  flex-direction: column;

  @media (prefers-color-scheme: dark) {
    background-color: $color-grey-900;
  }
}

.infoBlock {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;

  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);

  margin: 3rem;

  @media (prefers-color-scheme: dark) {
    box-shadow: 0 2px 20px rgba($color-grey-100, 0.1);
  }
}

.infoBlockHeader {
  background-color: #21476a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;

  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.header {
  color: $color-grey-100;
  font-size: 1.8rem;
}

.infoBlockContent {
  background-color: white;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  padding: 2rem;
  height: 22rem;
  overflow-y: auto;

  @media (prefers-color-scheme: dark) {
    background-color: $color-grey-900;
  }
}

.note {
  display: grid;
  grid-template-columns: 2fr 1fr;
  position: relative;

  &:not(:first-child) {
    margin-top: 2rem;
  }

  &::after {
    content: '';
    width: 6rem;
    height: 0.5rem;
    background-color: #419ea1;
    border-radius: 2rem;
    margin-top: 0.5rem;
  }

  @include respond(phone) {
    display: flex;
    flex-direction: column;
  }
}

.noteContent {
  font-size: 1.6rem;
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;
  hyphens: auto;
}

.noteDate {
  grid-column: 1 / 2;
  margin-top: 1rem;
  font-size: 1.4rem;
  color: $color-grey-700;
}

.by {
  color: $color-grey-700;
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.noteCreator {
  grid-column: 2 / 3;
  justify-self: center;
  align-self: start;
  font-size: 1.6rem;
  grid-row: 1 / 3;

  color: $color-grey-900;

  display: flex;
  align-items: center;

  @media (prefers-color-scheme: dark) {
    color: $color-grey-100;
  }
}

.iconBox {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;

  @include respond(phone) {
    top: auto;
    bottom: 0;
  }
}

.icon {
  width: 1.75rem;
  height: 1.75rem;
  margin-bottom: 0.5rem;
  transition: all 0.2s;
  fill: rgba(0, 0, 0, 0.7);

  &:hover {
    transform: translateY(-2px);
  }

  @media (prefers-color-scheme: dark) {
    fill: $color-grey-300;
  }
}

.trash {
  fill: $color-danger-300;
}

.btnBlock {
  display: flex;
  //justify-content: center;
  margin: 3rem;

  & > * {
    margin-left: 1rem;
  }

  @include respond(tab-port) {
    flex-direction: column;

    & > * {
      margin-top: 1rem;
    }
  }
}
