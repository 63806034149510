.TopBar {
  grid-column: 3 / full-end;
  /*background-color: #944317;*/
  border-bottom: 2px solid #ebf0f3;
  display: flex;
}

.tbdBox {
  flex: 1 0 auto;
}

@media only screen and (max-width: 56.25em) {
  .TopBar {
    display: none;
  }
}
