@import 'src/scss/mixins';
@import 'src/scss/variables';

.box {
  padding: 2rem;
  font-size: 1.8rem;

  @include respond(tab-port) {
    width: auto;
    height: auto;
    padding: 0;
  }

  & > * {
    margin-top: 1rem;
  }
}

.name {
  font-size: 3rem;
  font-weight: 300;
}

.price {
  font-size: 2.5rem;
  font-weight: 400;
  margin-top: 1rem;
}

.description {
  //margin-top: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  //width: 50%;
  align-items: flex-end;

  & div select {
    width: 20rem;
    @include respond(tab-port) {
      width: auto;
    }
  }
}

.selectBoxes {
  align-self: flex-start;
  display: flex;

  @include respond(phone) {
    flex-direction: column;
  }

  margin-bottom: 2rem;
}

.dynamic {
  font-size: 2rem;
  transition: all 0.2s;
}

.active {
  color: $color-grey-900;

  @media (prefers-color-scheme: dark) {
    color: $color-grey-300;

  }
}

.disabled {
  color: $color-grey-500;
}
