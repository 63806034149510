@import 'src/scss/variables';
@import 'src/scss/mixins';

.chartContainer {
  padding-top: 5rem;
  padding-left: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  //align-items: center;
  //justify-content: space-around;
  grid-gap: 2rem;
  overflow-x: hidden;

  //flex-direction: column;
  //border: 2px solid black;

  position: relative;

  //height: 100%;

  @include respond(tab-port) {
    //padding-top: ;
    display: flex;
    justify-content: center;
    flex-direction: column;
    //margin-top: 2.5rem;
    height: auto;

    & > * {
      padding-top: 5rem;
    }
  }
}





.bar {
  grid-column: 3 / 4;
  order: 2;

  @include respond(tab-port) {
    width: 100%;
    height: 40vh;
  }

  @include respond(big-desktop) {
    //width: 45%;
  }
}

.doughnut {
  grid-column: 2 / 4;
  grid-row: 1 / 3;
  position: relative;
  @include respond(tab-port) {
    width: 100%;
  }

  @include respond(big-desktop) {
    //width: 45%;
  }
}

.total {
  @include absCenter();

  top: 53%;

  font-size: 4rem;
  font-weight: 700;
  color: $color-success-500;
  @include respond(big-desktop) {
    top: 52%;
  }

  @include respond(tab-port) {
    top: 58%;
  }
}



.active {
  background-color: $color-success-500;
}





.titleBox {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 1rem;
  left: 2rem;

  @include respond(tab-port) {
    left: 50%;
    top: 2rem;

    transform: translate(-50%, -50%);
  }
}

.year {
  font-size: 2.5rem;
  order: 2;
  margin-top: -1.5rem;
}

.month {
  font-size: 3rem;
}

.stackedBar {
  grid-column: 1 / -1;
  grid-row: 4;
}
