@import 'src/scss/mixins';
@import 'src/scss/variables';

.tableContainer {
  grid-column: 1 / -1;
  grid-row: 3 / -1;
  padding: 0 4rem;
  height: 100%;
  overflow-y: auto;

  /*align-self: start;*/
  margin-bottom: 2rem;

  @include respond(tab-port) {
    grid-column: 1 / -1;
  }
}

.table {
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 2rem;
}

@media only screen and (max-width: 56.25em) {
  .tableContainer {
    align-self: start;
    height: 100%;
  }
}

@media only screen and (max-width: 37.5em) {
  .tableContainer {
    padding: 0;
  }
}
