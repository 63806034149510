@import 'src/scss/mixins';
@import 'src/scss/variables';

.list {
  display: flex;
  flex-direction: column;
  padding: 2rem;

  grid-column: 1 / -1;
  overflow-y: auto;
  height: 38rem;

  //& > :nth-child(even) {
  //  background-color: $color-secondary-100;
  //}

  @include respond(phone) {
    padding: 0;
  }
}

.listItem {
  display: flex;
  justify-content: space-between;
  //height: 6rem;
  padding: 1rem;
  transition: all 0.2s;
  @media (prefers-color-scheme: dark) {
    background-color: $color-grey-700;
  }

  &:nth-of-type(even) {
    background-color: $color-secondary-100;

    @media (prefers-color-scheme: dark) {
      background-color: $color-grey-800;
    }
  }
}

.listHeader {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  color: $color-grey-100;
  font-weight: 500;
  background-color: $color-secondary-700;
}

.name {
  width: 30%;

  @include respond(phone) {
    width: 20%;
  }
}

.description {
  width: 50%;
  //height: 5rem;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.price {
  width: 5%;

  @include respond(phone) {
    width: 15%;
  }
}

.quantity {
  width: 5%;
}

.size {
  width: 5%;

  @include respond(phone) {
    width: 10%;
  }
}

.icon {
  width: 4rem;
  height: 4rem;
  transition: all 0.2s;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
}

.danger {
  fill: $color-danger-500;
}

.row {
  display: flex;

  &:not(last-child) {
    margin-bottom: 1rem;
  }
}

.label {
  color: $color-grey-700;
}

.content {
  margin-left: 1rem;
}

.green {
  color: #349053;
}

.bold {
  font-weight: 700;
}

.accordionHeader {
  background-color: $color-primary-500 !important;

  @media (prefers-color-scheme: dark) {
    background-color: $color-secondary-700 !important;

  }
  transition: all 0.4s;
}

.listItemAccordion {
  display: flex;
  justify-content: space-between;
  //height: 6rem;
  padding: 1rem;
  transition: all 0.4s;

  &:nth-child(odd) {
    background-color: $color-primary-100;

    @media (prefers-color-scheme: dark) {
      background-color: $color-grey-800;

    }
  }
}

.hidden {
  //display: none;
  position: absolute;
  top: -20rem;
  left: -20rem;
  visibility: hidden;
  //opacity: 0;
  transform: translateY(-10rem);
  //transition: all 2s;
}
