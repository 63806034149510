@import 'src/scss/mixins';
@import 'src/scss/variables';

.AlertBox {
  width: 50%;
  margin-left: 10rem;

  margin-top: 10rem;

  display: flex;

  @include respond(tab-port) {
    width: 100%;
    padding: 0;
    margin: 10rem auto 0 auto;
    //margin-top: 10rem;
    //display: block;
    justify-content: center;
  }
}
