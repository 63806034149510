@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin transformItem($top, $left) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/*
0 - 600px:     Phone
600 - 900px:   Tablet portrait
900 - 1200:    Tablet landscape
[1200 - 1800]  is where our normal styles apply - Desktop first approach
1800px + :     Big Desktop

$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop


ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    }
    //600px
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    }
    //900px
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    }
    //1200px
  }
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    }
    // 1800px
  }
}
