@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/typography';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    // width < 1200
    font-size: 56.25%; //1 rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    // width < 900
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; //1 rem = 12px, 12/16 = 75%
  }
}

body {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: $font-primary, sans-serif;
  font-weight: 300;
  line-height: 1.6;
  background-color: $color-grey-100;

  color: $color-grey-900;
  font-size: $font-md;

  @include respond(phone) {
    line-height: 1.7;
  }

  // Handle dark mode
  @media (prefers-color-scheme: dark) {
    background: $color-grey-900;
    color: $color-grey-300;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: grid;
  grid-template-rows: 8rem minmax(92vh, min-content);
  grid-template-columns:
    [full-start] minmax(6rem, 1fr) [center-start] repeat(
      8,
      [col-start] minmax(min-content, 14.25rem) [col-end]
    )
    [center-end] minmax(6rem, 1fr) [full-end];

  @include respond(tab-port) {
    grid-template-columns:
      [full-start] minmax(0.5rem, 1fr) [center-start] repeat(
        8,
        [col-start] minmax(min-content, 14.25rem) [col-end]
      )
      [center-end] minmax(0.5rem, 1fr) [full-end];

    grid-template-rows: auto;
    overflow-x: hidden;
  }
}
h1,
h2,
h3,
h4 {
  font-family: $font-display;
}
