@import 'src/scss/mixins';
@import 'src/scss/variables';

.membersContainer {
  //grid-column: 2 / -1;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 26vh 4vh 60vh;
  height: 100%;

  @include respond(tab-port) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
  }
}

.buttonContainer {
  grid-column: 6 / -1;
  display: flex;
  align-items: flex-end;

  @include respond(tab-port) {
    grid-column: 1 / -1;
    justify-content: flex-end;
    margin-right: 4rem;
    margin-bottom: 1rem;
  }
}

.editMembers {
  display: grid;
  grid-row: 2 / -1;
  grid-column: 1 / -1;
}

.attendanceSearchBox {
  grid-row: 2 / 3;
  padding-left: 4rem;
  position: relative;
  grid-column: 1 / 3;

  @include respond(tab-port) {
    padding-right: 4rem;
  }
}

.sortContainer {
  grid-column: 1 / 2;
  grid-row: 3 / -1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.sortBox {
  border: 2px solid $color-primary-100;
  padding: 8rem 4rem;

  &:not(:first-child) {
    margin-top: 8rem;
  }
}

.sortList {
  list-style: none;
}

.listItem {
  transition: all 0.2s;

  &:hover {
    transform: translateY(-2px);
  }
}
