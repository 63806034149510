@import 'src/scss/variables';
@import 'src/scss/mixins';

.container {
  height: 90vh;
  width: 100%;
  //padding-top: 2rem;

  max-height: 90vh;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  //grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 6rem 1fr;

  //overflow-y: paged-y;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
  grid-row: 2 / 3;

  & > * {
    border: 2px solid $color-primary-500;
    align-self: center;

    &:not(:first-child) {
      margin-top: 8rem;
    }
  }

  @include respond(phone) {
    display: none;
  }
}

.memberships {
  grid-column: 2 / 4;
  grid-row: 2 / 3;
  //margin-top: 4rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 2rem;
  //grid-template-rows: repeat(4, 1fr);

  overflow-y: auto;

  @include respond(tab-port) {
    grid-column: 1 / -1;
    overflow-y: auto;
    margin-top: 3rem;
  }
}

.membershipOptions {
  grid-column: 4 / 5;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond(tab-port) {
    grid-row: 1 /2;
  }
}
