@import 'src/scss/mixins';
@import 'src/scss/variables';

.orders {
  display: grid;
  //grid-template-columns: 2fr 4fr;

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
  }

  .filter {
    @include respond(tab-port) {
      display: none;
    }
  }
}
