@import 'src/scss/variables';
@import 'src/scss/mixins';

.userDetailBlock {
  position: relative;

  background: linear-gradient(
    to bottom,
    rgba($color-secondary-700, 1),
    85%,
    rgba($color-primary-700, 1)
  );

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 0;
}

.backButton {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.6rem;
  width: 100%;
  height: 6rem;

  background-color: $color-secondary-600;
  color: white;

  transition: all 0.2s;

  &:hover {
    background-color: $color-secondary-500;
    cursor: pointer;
  }
}

.imageBlock {
  width: 15rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.image {
  width: 100%;
  border-radius: 50%;
}

.status {
  font-size: 1.2rem;
  background-color: #20bf6b;
  border-radius: 2px;
  width: 5.5rem;
  color: white;
  text-align: center;
  padding: 2px;
}

.statusDanger {
  background-color: $color-danger-500;
}

.name {
  font-size: 3.5rem;
  color: #ebeff4;
  margin-top: 1rem;
  text-align: center;
}

.since {
  font-size: 1.6rem;
  color: #ebeff4;
}

.personalBlock {
  padding: 0 2rem;
  margin-top: 6rem;
  /*margin-bottom: 2rem;*/

  width: 100%;
  /*text-align: left;*/

  /*overflow-x: scroll;*/
}

.personalHeader {
  font-size: 1.8rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.3);
}

.infoBlock {
  display: flex;
  justify-content: space-between;
  font-size: 1.8rem;
  color: #ebeff4;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 1rem;
}

.infoBlock > * {
  margin-top: 1.5rem;
}
