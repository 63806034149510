@import 'src/scss/variables';
@import 'src/scss/mixins';

.input {
  padding: 1rem 3rem !important;
  width: 100%;
}

.checkout {
  position: absolute;
  top: 0.8rem;
  left: 0.5rem;
  height: 2rem;
  width: 2rem;
  fill: #979797;
}

.inputCheckout {
  padding: 1rem 3rem !important;
  width: 50%;
}

.searchedUsers {
  height: 10rem;
  width: 100%;
  overflow-y: auto;
  list-style: none;
  box-shadow: 0 2px 2rem rgba($color-grey-900, 0.1);

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-template-rows: repeat(4, 1fr);
  border-left: 2px solid var(--color-light-grey);
  border-bottom: 2px solid var(--color-light-grey);
  border-right: 2px solid var(--color-light-grey);
}

.searchedUser {
  font-size: 1.4rem;
  padding: 0.5rem;
  border-bottom: 2px solid var(--color-light-grey);
}

.searchedUser:nth-child(odd) {
  border-right: 2px solid var(--color-light-grey);
}

.icon {
  position: absolute;
  top: 5.7rem;
  left: 0.5rem;
  height: 2rem;
  width: 2rem;
  fill: #979797;
}

.iconNoList {
  position: absolute;
  top: 1rem;
  left: 0.6rem;
  height: 2rem;
  width: 2rem;
  fill: #979797;
}

@media only screen and (max-width: 37.5em) {
  .icon {
    top: 11.4rem;
  }
}
