@import 'src/scss/mixins';
@import 'src/scss/variables';

.billingContainer {
  height: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  grid-auto-rows: 25rem;

  @include respond(tab-port) {
    margin-top: 5.5rem;
  }
}

.modal {
  width: 50vw;
  max-height: 75vh;

  @include respond(phone) {
    width: 98vw;
  }
}
///////////////////////
// SEPERATE COMPONENT
///////////////////////

.memberList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;

  &:nth-child(even) {
    background-color: $color-primary-100;
  }

  &:nth-child(odd) {
    background-color: $color-grey-100;
  }

  @include respond(tab-port) {
    font-size: 2rem;
  }
}

.listHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  background-color: $color-primary-500;

  color: $color-grey-100;
  font-weight: 400;
  font-size: 1.8rem;
  @include respond(tab-port) {
    font-size: 1.9rem;
  }
}

.name {
  width: 15rem;
}

.date {
  width: 10rem;
}

.amount {
  width: 7rem;
}

.discount {
  width: 8rem;
}

.title {
  font-weight: 300;
}
