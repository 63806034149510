@import "src/scss/mixins";
@import "src/scss/variables";


.Navigation {
  grid-column: 1 / 3;
  grid-row: 1 / -1;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: $color-secondary-900;

  transition: all 1s;

  @include respond(tab-port) {
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 80%;
    height: 100%;
    z-index: 200;
    transition: transform 0.3s ease-out;

    padding: 2rem 1rem;
    margin-top: 0;
    transform: translateX(-100%);

  }


}

.open {
  transform: translateX(0);
}
