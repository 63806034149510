@import 'src/scss/variables';
@import 'src/scss/mixins';

.Button {
    display: inline-block;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 1.4rem 3rem;
    border-radius: 10rem;
    transition: all .2s;
    font-size: 1.6rem;
    font-weight: 400;
    position: relative;
}

/*.Button2:first-of-type {*/
/*    margin-left: 0;*/
/*    padding-left: 0;*/
/*}*/

.Button:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 2px rgba(0, 0, 0, .3);
}

.Button:disabled {
    background-color: #726e6e;
    cursor: not-allowed;
    transform: translateY(0);
}

.Success {
    color: white;
    background-color: #349053;
}

.Danger {
    color: white;
    background-color: #944317;
}

.ExpandedDateForm {
    /*width: 20rem;*/
}

.Nav {
    margin-top: 12rem;
    width: max-content;
    /*align-self: center;*/
    margin-left: 4rem;

}