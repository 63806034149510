@import 'src/scss/mixins';
@import 'src/scss/variables';

.Loader,
.Loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.Loader {
  //margin: 60px auto;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  @include transformItem(45%, 45%);

  @media (prefers-color-scheme: dark) {
    border-top: 1.1em solid rgba($color-grey-500, 0.2);
    border-right: 1.1em solid rgba($color-grey-500, 0.2);
    border-bottom: 1.1em solid rgba($color-grey-500, 0.2);
    border-left: 1.1em solid $color-grey-500;
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
