@import 'src/scss/mixins';
@import 'src/scss/variables';

.attended {
    list-style: none;
    width: 100%;
    border: 2px solid $color-grey-100;
    margin-top: 2rem;
    height: 30rem;
    box-shadow: 0 2px 1rem rgba(0, 0, 0, .2);
    border-radius: 1rem;
    margin-bottom: 2rem;
    padding: 1rem;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-auto-rows: 3rem;
    overflow-y: auto;
}

.user {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
}

.icon {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: all .2s;
    padding: 5px;
    border-radius: 5px;
}

.icon:hover {
    background-color: #5e747f;
    transform: translateY(-2px);
    fill: white
}

.icon > * {
    pointer-events: none;
}

.icon:not(:first-child) {
    margin-left: 1rem;
    fill: $color-danger-500;
}

.name {
    width: 20rem;
}

@media (prefers-color-scheme: dark) {
    .name {
        color: $color-grey-100;
    }
}

