@import "src/scss/variables";
@import "src/scss/mixins";

.CalendarHeader {
  grid-column: 1 / -1;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  //background-color: $color-grey-100;
  position: relative;

  & h3 {
    font-family: $font-display, serif;
    font-weight: 400;
    font-size: 2.6rem;
    width: 20rem;
    text-align: center;

  }

  @include respond(phone) {
    justify-content: flex-end;
    padding-right: 2rem;
  }
}

.tableHeader {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-around;
  font-size: 1.8rem;
}

.Icon {
  width: 3rem;
  height: 3rem;

  & > * {
    pointer-events: none;
  }

  @media (prefers-color-scheme: dark) {
    fill: $color-grey-100;
  }
}

.Button {
  position: absolute;
  right: 5rem;
  top: 0;

  height: 3rem;
  width: 10rem;

  background-color: white;
  border-radius: 6px;
  border: 2px solid var(--color-light-grey);
  transition: all 0.2s;

  &:hover {
    background-color: var(--color-light-grey);
    color: white;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }

  @include respond(tab-port) {
    display: none;
  }
}
