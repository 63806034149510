@import 'src/scss/variables';
@import 'src/scss/mixins';

.Input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.Input select {
  /*width: 30%;*/
}

.Label {
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  color: $color-grey-800;
  margin-bottom: 0.75rem;

  @media (prefers-color-scheme: dark) {
    color: $color-grey-300
  }

  @include respond(phone) {
    font-size: 2rem;
  }
}

.InputElement {
  display: block;
  font-family: inherit;
  font-size: 1.5rem;
  color: inherit;
  padding: 1.25rem 1.75rem;
  border: none;
  width: 100%;
  background-color: #f2f2f2;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  @media (prefers-color-scheme: dark) {
    background-color: $color-grey-800;
  }

  @include respond(phone) {
    font-size: 1.8rem;
  }
}

.InputElement:focus {
  outline: none;
}

.valid {
  border-bottom: 3px solid #55c57a;
}

.invalid {
  border-bottom: 3px solid #ff7730;
}

.photo {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.photoLabel {
  color: inherit;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid $color-primary-500;
  padding: 3px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
  font-size: 2rem;

  &:hover {
    background-color: $color-primary-500;
    color: #fff;
    -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}

.textarea {
  width: 50vw;
  height: 15vh;
}

@media only screen and (max-width: 37.5em) {
  .textarea {
    width: 100%;
  }
}
