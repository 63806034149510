@import 'src/scss/variables';
@import 'src/scss/mixins';

.adminBox {
  flex: 0 1 auto;
  width: 30rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  border-left: 2px solid #ebf0f3;
  //padding: 1rem;
}

.imageBlock {
  grid-row: 1 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 1rem;
}

.image {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  /*padding: 1rem;*/
}

.name {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 400;
  //color: $color-grey-800;
  align-self: center;
}

.role {
  text-transform: capitalize;
  font-size: 1.6rem;
}
