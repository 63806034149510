.attendanceHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 3rem;
}

.className {
  font-size: 3rem;
  font-weight: 300;
}

.time {
  font-size: 3rem;
  font-weight: 300;
}

@media only screen and (max-width: 37.5em) {
  .attendanceHeader {
    flex-direction: column;
    align-items: end;
  }

  .title {
    font-size: 2rem;
  }
  .className {
    font-size: 2rem;
  }

  .time {
    font-size: 2rem;
  }
}
