@import 'src/scss/variables';
@import 'src/scss/mixins';


.main {
  display: grid;
  //flex-direction: column;
  //align-items: center;
  padding-top: 7rem;

  grid-column-gap: 2rem;

  grid-template-columns: 1fr 1fr 1fr;
}

.title {
  color: $color-grey-800;
  grid-column: 1 / -1;
}

.logBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include respond(tab-port) {
    grid-column: 1 / -1;
    order: 2

  }
}

.attendanceBox {
  width: 40rem;
  padding: 2rem 4rem;
  border-radius: 5rem;
  background-color: $color-primary-300;

  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;

  &:not(:last-child) {
    margin-bottom: 5rem;
  }
}

.className {
  grid-column: 1 / 2;
  font-size: 2rem;
}

.date {
  font-size: 1.8rem;
}

.time {
  font-size: 1.6rem;
  font-weight: 700;
}

.instructor {

}

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.2rem;

  @include respond(tab-port) {
    grid-column: 1 / -1;
  }


}

.statBox {
  background-color: $color-secondary-400;
  width: 30rem;
  padding: 4rem;
  border-radius: 6rem;


  &:not(:first-child) {
    margin-top: 4rem;
  }
}

.statTitle {
  text-align: center;
  color: $color-secondary-900;
  font-size: 2rem;
}

.content {
  text-align: center;
  font-size: 4rem;
  color: $color-secondary-200;
  font-weight: 700;
  //margin-top: 2rem;
}