@import 'src/scss/mixins';
@import 'src/scss/variables';

.userProfile {
  height: 100%;

  display: grid;
  grid-template-columns: 2fr 4fr;

  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
  }
}

.full {
  grid-column: 1 / -1;
  height: 100%;
  position: relative;
}

.stripeContainer {
  //@include absCenter();
  //width: 80%;
  //height: 50%;
  padding: 10rem;
  display: flex;
  flex-direction: column;

  @include respond(tab-port) {
    position: relative;
    //height: 30vh;
    margin-top: 10rem;
    padding: 4rem 2rem;
  }
}

.selectedPlan {
  margin-left: 10rem;
  font-size: $font-xl;
}

.options {
  padding: 10rem;
  display: flex;
  justify-content: space-around;

  @include respond(tab-port) {
    flex-direction: column;
  }
}

.optionsBox {
  padding: 5rem;
  background-color: $color-grey-300;
  border: 2px solid transparent;

  @media (prefers-color-scheme: dark) {
    background-color: $color-grey-800;
  }

  @include respond(tab-port) {
    margin-bottom: 4rem;
  }

  & > * {
    pointer-events: none;
  }

  transition: all 0.2s;
}

.save {
  margin-left: 10rem;
  padding: 2rem 3rem;
  background-color: $color-primary-700;
  width: max-content;
}

.active {
  border: 2px solid $color-primary-500;
}
