.Alert {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 9999;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 400;
    text-align: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 1.6rem 15rem;
    box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.25);
}

.Success {
    background-color: #20bf6b
}

.Error {
    background-color: #eb4d4b;
}

@media only screen and (max-width: 56.25em) {
    .Alert {
        padding: 5rem 0;
        width: 100%;
    }
}