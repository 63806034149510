@import 'src/scss/variables';
@import 'src/scss/mixins';

.attendanceSearchBox {
  position: relative;
  display: flex;
}

.cartItem {
  display: flex;
  justify-content: space-between;

  padding: 2rem;
}

.checkout {
  width: 50vw;
  height: 50vh;

  @include respond(tab-port) {
    width: 100%;
    height: 100%;
  }
}
