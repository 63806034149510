// Color Primary
$color-primary-900: #2f1200;
$color-primary-800: #cc4e00;
$color-primary-700: #ff680a;
$color-primary-600: #ff751f;
$color-primary-500: #ff8133;
$color-primary-400: #ff9a5c;
$color-primary-300: #ffb485;
$color-primary-200: #ffcdad;
$color-primary-100: #ffe6d6;

// Color Secondary
$color-secondary-900: #0a2b38;
$color-secondary-800: #145570;
$color-secondary-700: #1d7fa9;
$color-secondary-600: #2295c5;
$color-secondary-500: #27aae1;
$color-secondary-400: #42b5e5;
$color-secondary-300: #5dbfe8;
$color-secondary-200: #aedff4;
$color-secondary-100: #e4f4fb;

// Pure Colors
$white: #fff;
$black: #000;

// Greys
$color-grey-100: #fcfcfc;
$color-grey-200: #f3f3f3;
$color-grey-300: #e3e3e3;
$color-grey-400: #d9d9d9;
$color-grey-500: #c8c5c5;
$color-grey-600: #ababab;
$color-grey-700: #575757;
$color-grey-800: #3b3a3a;
$color-grey-900: #2a2a2a;

// Danger
$color-danger-900: #5f1717;
$color-danger-800: #881a1b;
$color-danger-600: #b82020;
$color-danger-500: #dc3030;
$color-danger-300: #e36363;
$color-danger-200: #f4aaaa;
$color-danger-100: #fce8e8;

// Success
$color-success-500: #20bf6b;

// Stripe
$color-stripe: #635bff;

// Warning

// FONT
$font-primary: 'Ubuntu', sans-serif;
$font-display: 'Poppins', sans-serif;
$font-md: 1.6rem;
$font-lg: 1.8rem;
$font-xl: 2rem;
$font-big: 3rem;
