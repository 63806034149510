@import "src/scss/_variables.scss";

.button {
  display: none;

  background-color: $color-secondary-500;
  height: 7rem;
  width: 7rem;
  position: fixed;
  top: 3rem;
  left: 3rem;
  border-radius: 50%;
  z-index: 2000;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;

  transition: transform 0.6s;
}

@media only screen and (max-width: 56.25em) {
  .button {
    display: inline-block;
  }
}

@media only screen and (max-width: 37.5em) {
  .button {
    top: 1.5rem;
    left: 1.5rem;
  }
}

/*  ICON  */
.icon {
  position: relative;
  margin-top: 3.5rem;
  width: 3rem;
  height: 2px;
  background-color: white;
  display: inline-block;
}

.icon::before {
  width: 3rem;
  height: 2px;
  background-color: white;
  display: inline-block;
}

.icon::after {
  width: 3rem;
  height: 2px;
  background-color: white;
  display: inline-block;
}

.icon::before {
  content: '';
  position: absolute;
  left: 0;
  transition: all 0.2s;
}

.icon::after {
  content: '';
  position: absolute;
  left: 0;
  transition: all 0.2s;
}

.icon::before {
  top: -0.8rem;
}
.icon::after {
  top: 0.8rem;
}

.open {
  transform: translateX(70vw);
}

.open .icon {
  background-color: transparent;
}

.open .icon::before {
  top: 0;
  transform: rotate(135deg);
}

.open .icon::after {
  top: 0;
  transform: rotate(-135deg);
}
